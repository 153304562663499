// src/components/ChatInterface.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  MoreVertical, Search, Smile, X,
  Paperclip, Send, Mic, StopCircle, Settings, LogOut, XCircle, CheckCircle, Sparkles, Building,
  Volume2, VolumeX, Vibrate
} from 'lucide-react'; // Importando ícones necessários
import { useNavigate } from 'react-router-dom';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import EmojiPicker from 'emoji-picker-react';
import PropTypes from 'prop-types';
import iconBackground from '../assets/Icon_gray.svg'; // Importando o ícone de fundo

const ChatInterface = () => {
  const [inputMessage, setInputMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Estado para controlar a visibilidade do picker de emoji
  const [file, setFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [contactSearchTerm, setContactSearchTerm] = useState('');
  const [selectedChat, setSelectedChat] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isRecorded, setIsRecorded] = useState(false);

  // Estados para notificações sonoras e tremor de tela
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [shakeEnabled, setShakeEnabled] = useState(true);

  const navigate = useNavigate();
  const emojiPickerRef = useRef(null);
  const menuRef = useRef(null);
  const messagesEndRef = useRef(null);
  const mainContainerRef = useRef(null); // Referência para o contêiner principal

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);
  const recordingIntervalRef = useRef(null);

  const sanitizeInput = (input) => {
    return input.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  const playNotificationSound = () => {
    if (soundEnabled) {
      const sound = new Audio('path/to/your/notification-sound.mp3'); // Substitua pelo caminho do arquivo de som
      sound.play();
    }
  };

  const shakeScreen = () => {
    if (shakeEnabled) {
      const chatWindow = document.getElementById('chatWindow'); // Certifique-se de que o ID está correto
      if (chatWindow) {
        chatWindow.classList.add('shake');
        setTimeout(() => chatWindow.classList.remove('shake'), 500); // Remove a classe após a animação
      }
    }
  };

  const shakeContact = (chatId) => {
    const contactElement = document.getElementById(`contact-${chatId}`);
    if (contactElement) {
      contactElement.classList.add('shake');
      setTimeout(() => contactElement.classList.remove('shake'), 500);
    }
  };

  const handleNewMessage = (chatId) => {
    playNotificationSound();
    shakeScreen();
    shakeContact(chatId); // Aplica o tremor no contato específico
  };

  const mockChats = [
    { id: 1, name: 'João Silva', lastMessage: 'Olá, tudo bem?', time: '2024-09-17T10:30', unreadCount: 1 },
    { id: 2, name: 'Maria Oliveira', lastMessage: 'Vamos nos encontrar hoje?', time: '2024-09-17T09:45', unreadCount: 0 },
    { id: 3, name: 'Carlos Santos', lastMessage: 'Obrigado pela ajuda!', time: '2024-09-16T19:00', unreadCount: 2 },
    { id: 4, name: 'Ana Paula', lastMessage: 'Bom dia!', time: '2024-09-17T08:15', unreadCount: 0 },
    { id: 5, name: 'Pedro Henrique', lastMessage: 'Até mais tarde.', time: '2024-09-16T16:00', unreadCount: 3 },
  ];

  // Ordena os contatos pela hora da última mensagem
  const sortedChats = [...mockChats].sort((a, b) => new Date(b.time) - new Date(a.time));

  const filteredChats = contactSearchTerm
    ? sortedChats.filter((chat) =>
        chat.name.toLowerCase().includes(contactSearchTerm.toLowerCase())
      )
    : sortedChats;

  const [mockMessages, setMockMessages] = useState({
    1: [
      { id: 1, text: 'Olá! Como vai?', sender: 'me', time: '10:30' },
      { id: 2, text: 'Tudo bem, e você?', sender: 'other', time: '10:31' },
    ],
    2: [
      { id: 3, text: 'Vamos nos encontrar hoje?', sender: 'other', time: '09:45' },
      { id: 4, text: 'Claro, a que horas?', sender: 'me', time: '09:46' },
    ],
  });

  const messages = selectedChat ? mockMessages[selectedChat.id] || [] : [];

  const filteredMessages = searchTerm
    ? messages.filter((message) =>
        ((message.text || '') + (message.audioUrl ? ' [Áudio]' : '')).toLowerCase().includes(searchTerm.toLowerCase())
      )
    : messages;

  useEffect(() => {
    const handleClickOutsideEmojiPicker = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false); // Fecha o picker de emoji ao clicar fora
      }
    };

    if (showEmojiPicker) {
      document.addEventListener('mousedown', handleClickOutsideEmojiPicker);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideEmojiPicker);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEmojiPicker);
    };
  }, [showEmojiPicker]);

  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener('mousedown', handleClickOutsideMenu);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [showMenu]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [filteredMessages, showEmojiPicker]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate('/');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleSettingsClick = useCallback(() => {
    navigate('/settings');
  }, [navigate]);

  const handleLogout = useCallback(async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Erro ao sair:', error);
      alert('Erro ao sair. Tente novamente.');
    }
  }, [navigate]);

  const sendMessage = useCallback(() => {
    if (inputMessage.trim() && selectedChat) {
      const sanitizedMessage = sanitizeInput(inputMessage);

      const newMessage = {
        id: Math.random(),
        text: sanitizedMessage,
        sender: 'me',
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      setMockMessages((prevMessages) => {
        const chatId = selectedChat.id;
        const updatedChatMessages = [...(prevMessages[chatId] || []), newMessage];
        return {
          ...prevMessages,
          [chatId]: updatedChatMessages,
        };
      });

      setInputMessage('');
      handleNewMessage(selectedChat.id); // Chama as funções de notificação e tremor no contato
    }
  }, [inputMessage, selectedChat, setMockMessages]);

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedChat) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        alert('O arquivo é muito grande. O tamanho máximo é 5MB.');
        return;
      }

      const newMessage = {
        id: Math.random(),
        text: `Arquivo: ${selectedFile.name}`,
        fileUrl: URL.createObjectURL(selectedFile),
        sender: 'me',
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      setMockMessages((prevMessages) => {
        const chatId = selectedChat.id;
        const updatedChatMessages = [...(prevMessages[chatId] || []), newMessage];
        return {
          ...prevMessages,
          [chatId]: updatedChatMessages,
        };
      });

      setFile(selectedFile);
      event.target.value = null;
    }
  };

  const startRecording = async () => {
    if (!selectedChat) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      const recorder = new MediaRecorder(stream);
      mediaRecorderRef.current = recorder;
      audioChunksRef.current = [];

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      recorder.start();
      setIsRecording(true);
      setRecordingTime(0);
      setIsRecorded(false);

      recordingIntervalRef.current = setInterval(() => {
        setRecordingTime((prev) => prev + 1);
      }, 1000);
    } catch (error) {
      console.error('Erro ao acessar o microfone:', error);
      alert('Não foi possível acessar o microfone. Por favor, verifique as permissões.');
    }
  };

  const stopRecording = (shouldSend) => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      clearInterval(recordingIntervalRef.current);

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        const audioUrl = URL.createObjectURL(audioBlob);

        if (shouldSend) {
          const newMessage = {
            id: Math.random(),
            audioUrl: audioUrl,
            sender: 'me',
            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          };

          setMockMessages((prevMessages) => {
            const chatId = selectedChat.id;
            const updatedChatMessages = [...(prevMessages[chatId] || []), newMessage];
            return {
              ...prevMessages,
              [chatId]: updatedChatMessages,
            };
          });
        }

        setIsRecorded(true);
        setIsRecording(false);

        if (streamRef.current) {
          streamRef.current.getTracks().forEach((track) => track.stop());
          streamRef.current = null;
        }

        setRecordingTime(0);
      };
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState); // Alterna o estado de exibição do emoji picker
  };

  // Função para alternar o estado das notificações sonoras
  const toggleSound = () => {
    setSoundEnabled(!soundEnabled);
  };

  // Função para alternar o estado do tremor de tela e aplicar o tremor na ativação
  const toggleShake = () => {
    setShakeEnabled((prev) => {
      const newState = !prev;

      if (newState && mainContainerRef.current) {
        // Adiciona a classe 'shake' para iniciar a animação
        mainContainerRef.current.classList.add('shake');

        // Remove a classe após a duração da animação (0.5s)
        setTimeout(() => {
          mainContainerRef.current.classList.remove('shake');
        }, 500); // 500ms = 0.5s
      }

      return newState;
    });
  };

  const ChatListItem = React.memo(({ chat, selectedChat, setSelectedChat }) => (
    <div
      id={`contact-${chat.id}`}
      className={`flex items-center p-3 hover:bg-[#242424] cursor-pointer border-b border-[#444654] ${
        selectedChat && selectedChat.id === chat.id ? 'bg-[#242424]' : ''
      }`}
      onClick={() => setSelectedChat(chat)}
    >
      <img src="/api/placeholder/50/50" alt={chat.name} className="rounded-full mr-3" />
      <div className="flex-1">
        <p className="font-semibold text-gray-200">{chat.name}</p>
        <p className="text-sm text-gray-400">{chat.lastMessage}</p>
      </div>
      <div className="flex items-center">
        {chat.unreadCount > 0 && (
          <span
            className="text-xs rounded-full px-2 py-1"
            style={{ backgroundColor: '#4c4c4d', color: 'white' }}
          >
            {chat.unreadCount}
          </span>
        )}
        <span className="text-xs text-gray-400 ml-2">{new Date(chat.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
      </div>
    </div>
  ));

  ChatListItem.propTypes = {
    chat: PropTypes.object.isRequired,
    selectedChat: PropTypes.object,
    setSelectedChat: PropTypes.func.isRequired,
  };

  // Componente Message com a melhoria para evitar renderizar o player de áudio quando não há audioUrl
  const Message = React.memo(({ text, fileUrl, audioUrl, sender, time }) => (
    <div className={`flex mb-4 ${sender === 'me' ? 'justify-end' : 'justify-start'}`}>
      <div className={`rounded-lg p-2 max-w-xs ${sender === 'me' ? 'bg-[#141414]' : 'bg-[#242424]'}`}>
        {text && <p className="text-white">{text}</p>}
        {fileUrl && (
          <a href={fileUrl} download className="text-blue-400 underline mt-2 block">
            Baixar arquivo
          </a>
        )}
        {audioUrl ? (
          <audio controls className="mt-2">
            <source src={audioUrl} type="audio/webm" />
            Seu navegador não suporta o elemento de áudio.
          </audio>
        ) : null}
        <span className="text-xs text-gray-300 float-right mt-1">{time}</span>
      </div>
    </div>
  ));

  Message.propTypes = {
    text: PropTypes.string,
    fileUrl: PropTypes.string,
    audioUrl: PropTypes.string,
    sender: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
  };

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Carregando...</div>;
  }

  return (
    <div
      ref={mainContainerRef} // Adiciona a referência aqui
      className="flex h-screen bg-[#242424] text-gray-200"
    >
      <div className="w-full md:w-1/3 flex flex-col bg-[#242424] border-r border-[#444654]">
        <div className="p-4 flex items-center justify-between">
          <div className="flex items-center">
            {user ? (
              <>
                <img
                  src={user.photoURL || '/api/placeholder/40/40'}
                  alt="Perfil do Usuário"
                  className="rounded-full"
                  width={40}
                  height={40}
                />
                <span className="ml-2 text-gray-200">{user.displayName || 'Usuário'}</span>
              </>
            ) : (
              <p>Carregando...</p>
            )}
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handleSettingsClick}
              className="flex items-center justify-center text-gray-400"
              aria-label="Configurações"
            >
              <Settings size={24} />
            </button>

            <div className="relative" ref={menuRef}>
              <button
                className="flex items-center justify-center text-gray-400"
                aria-label="Mais opções"
                onClick={() => setShowMenu(!showMenu)}
              >
                <MoreVertical size={24} />
              </button>
              {showMenu && (
                <div className="absolute right-0 top-full mt-4 w-56 bg-[#242424] shadow-lg rounded-lg border border-[#444654]" style={{ zIndex: 1000 }}>
                  {/* Notificações Sonoras */}
                  <button
                    className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#141414] flex items-center whitespace-nowrap"
                    aria-label="Notificações Sonoras"
                    onClick={toggleSound}
                  >
                    {soundEnabled ? (
                      <Volume2
                        size={16}
                        className={`mr-2 transition-colors duration-300 ${soundEnabled ? 'text-gray-200 opacity-100' : 'text-gray-500 opacity-50'}`}
                      />
                    ) : (
                      <VolumeX
                        size={16}
                        className={`mr-2 transition-colors duration-300 ${soundEnabled ? 'text-gray-200 opacity-100' : 'text-gray-500 opacity-50'}`}
                      />
                    )}
                    Notificações Sonoras
                  </button>
                  
                  {/* Vibração de Tela */}
                  <button
                    className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#141414] flex items-center"
                    aria-label="Vibração de Tela"
                    onClick={toggleShake}
                  >
                    <Vibrate
                      size={16}
                      className={`mr-2 transition-colors duration-300 ${shakeEnabled ? 'text-gray-200 opacity-100' : 'text-gray-500 opacity-50'}`}
                    />
                    Vibração de Tela
                  </button>

                  {/* Troca de Empresa */}
                  <button
                    className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#141414] flex items-center"
                    aria-label="Troca de Empresa"
                    onClick={() => navigate('/troca-de-empresa')}
                  >
                    <Building size={16} className="mr-2" /> Trocar de Empresa
                  </button>
                  
                  {/* Logout */}
                  <button
                    onClick={handleLogout}
                    className="w-full text-left px-4 py-2 text-gray-200 hover:bg-[#141414] flex items-center"
                  >
                    <LogOut size={16} className="mr-2" /> Desconectar
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="p-2">
          <div className="relative">
            <input
              type="text"
              placeholder="Pesquisar contatos"
              className="w-full p-2 pl-10 rounded bg-[#242424] text-gray-200 placeholder-gray-400 outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Pesquisar conversas"
              value={contactSearchTerm}
              onChange={(e) => setContactSearchTerm(e.target.value)}
            />
            <button
              className="absolute left-3 top-2.5 text-gray-400"
              aria-label="Pesquisar"
            >
              <Search size={20} />
            </button>
          </div>
        </div>

        <div className="overflow-y-auto flex-1">
          {filteredChats.length > 0 ? (
            filteredChats.map(chat => (
              <ChatListItem
                key={chat.id}
                chat={chat}
                selectedChat={selectedChat}
                setSelectedChat={setSelectedChat}
              />
            ))
          ) : (
            <div className="text-center text-gray-400 mt-4">
              Nenhuma conversa encontrada.
            </div>
          )}
        </div>
      </div>

      <div className="flex-1 flex flex-col relative" style={{
        backgroundColor: '#4c4c4d',
        backgroundImage: `url(${iconBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '300px 300px'
      }}>
        <div className="p-4 bg-[#242424] flex items-center justify-between relative h-16">
          <div className="flex items-center space-x-4">
            {selectedChat && (
              <>
                <img src="/api/placeholder/40/40" alt={selectedChat.name} className="rounded-full" />
                <span className="text-gray-200">{selectedChat.name}</span>
              </>
            )}
          </div>
          <div className="flex items-center space-x-4 text-gray-400">
            <div className="relative">
              <input
                type="text"
                placeholder="Pesquisar mensagens"
                className="w-64 p-2 pl-10 rounded bg-[#242424] text-gray-200 placeholder-gray-400 outline-none focus:ring-2 focus:ring-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                aria-label="Pesquisar mensagens"
              />
              <button
                className="absolute left-3 top-2.5 text-gray-400"
                aria-label="Pesquisar"
              >
                <Search size={20} />
              </button>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4" id="chatWindow">
          {selectedChat ? (
            filteredMessages.length > 0 ? (
              filteredMessages.map(message => (
                <Message key={message.id} {...message} />
              ))
            ) : (
              <div className="text-center text-gray-200 mt-4">
                Nenhuma mensagem encontrada.
              </div>
            )
          ) : (
            <div className="text-center text-gray-200 mt-4">
              Selecione uma conversa para começar
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {showEmojiPicker && (
          <div
            className="absolute left-0 right-0"
            style={{
              bottom: '64px',
              height: '50%',
              overflowY: 'auto',
              zIndex: 50,
            }}
            ref={emojiPickerRef}
          >
            <EmojiPicker
              theme="dark"
              onEmojiClick={(emojiData) => {
                setInputMessage((prev) => prev + emojiData.emoji);
              }}
              emojiStyle="native"
              skinTtonesDisabled
              searchDisabled
              previewConfig={{ showPreview: false }} // Desativar pré-visualização de emoji
              width="100%"
              height="100%"
            />
          </div>
        )}

        <div className="p-4 bg-[#242424] flex items-center">
          <div className="flex space-x-4">
            <div className="relative">
              <button
                className="flex items-center justify-center text-gray-400"
                onClick={toggleEmojiPicker}
                aria-label="Adicionar emoji"
              >
                {showEmojiPicker ? <X size={24} /> : <Smile size={24} />}
              </button>
            </div>
            <button className="flex items-center justify-center text-gray-400" aria-label="Anexar arquivo">
              <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                <Paperclip size={24} />
              </label>
              <input
                id="file-upload"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileUpload}
              />
            </button>
            {/* Adicionando o ícone de AI */}
            <button className="flex items-center justify-center text-gray-400" aria-label="AI">
              <Sparkles size={24} />
            </button>
          </div>

          {isRecording && (
            <div className="flex items-center space-x-2 text-gray-200 ml-4">
              <div>{`${Math.floor(recordingTime / 60)}:${('0' + (recordingTime % 60)).slice(-2)}`}</div>
              <button
                className="flex items-center justify-center text-green-400"
                onClick={() => stopRecording(true)}
                aria-label="Parar e enviar gravação"
              >
                <CheckCircle size={24} />
              </button>
              <button
                className="flex items-center justify-center text-red-400"
                onClick={() => stopRecording(false)}
                aria-label="Cancelar gravação"
              >
                <XCircle size={24} />
              </button>
            </div>
          )}

          {!isRecording && (
            <input
              type="text"
              placeholder="Digite uma mensagem"
              className="flex-1 p-2 rounded bg-[#242424] text-gray-200 placeholder-gray-400 mx-4 outline-none focus:ring-2 focus:ring-blue-500"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') sendMessage();
              }}
              aria-label="Digite uma mensagem"
              disabled={!selectedChat}
            />
          )}

          {!isRecording && inputMessage === '' && (
            <button
              className={`ml-2 flex items-center justify-center text-gray-400 ${!selectedChat ? 'opacity-50 cursor-not-allowed' : ''}`}
              onMouseDown={startRecording}
              aria-label="Gravar mensagem de áudio"
              disabled={!selectedChat}
            >
              <Mic size={24} />
            </button>
          )}

          {inputMessage && !isRecording && (
            <button
              className="ml-2 flex items-center justify-center text-gray-400"
              onClick={sendMessage}
              aria-label="Enviar mensagem"
              disabled={!selectedChat}
            >
              <Send size={24} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;
