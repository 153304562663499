import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Icon from '../assets/Icon.png';

const GoogleLogo = ({ size = 24 }) => (
  <svg viewBox="0 0 24 24" width={size} height={size} xmlns="http://www.w3.org/2000/svg">
    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" />
    <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" />
    <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" />
    <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" />
  </svg>
);

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');  // Novo estado para erros
  const auth = getAuth();
  const navigate = useNavigate();

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Login com e-mail bem-sucedido');
      navigate('/chat'); // Redireciona para a página de chat após login
    } catch (error) {
      setError('Erro ao fazer login com e-mail e senha. Verifique suas credenciais.');  // Exibe mensagem de erro
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      console.log('Login com Google bem-sucedido');
      navigate('/chat'); // Redireciona para a página de chat após login com Google
    } catch (error) {
      setError('Erro ao fazer login com Google.');  // Exibe mensagem de erro
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-center mb-6">
          <img src={Icon} alt="Logo" width={48} height={48} />
        </div>
        
        <form onSubmit={handleEmailLogin} className="space-y-4">
          {error && <div className="text-red-500">{error}</div>} {/* Exibe mensagem de erro */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Endereço de e-mail*
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Senha*
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          
          <button type="submit" className="w-full bg-[#242424] hover:bg-gray-800 text-white py-2 rounded">
            Continuar
          </button>
        </form>
        
        <div className="mt-4 text-center">
          <span className="text-sm text-gray-600">Não tem uma conta? </span>
          <a href="#" className="text-sm text-[#242424] hover:underline">Cadastrar</a>
        </div>
        
        <div className="mt-6 relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">ou</span>
          </div>
        </div>

        <div className="mt-6">
          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full flex items-center justify-center space-x-2 border border-gray-300 py-2 rounded"
          >
            <GoogleLogo size={24} />
            <span>Continuar com o Google</span>
          </button>
        </div>

        <div className="mt-6 text-center text-xs text-gray-500">
          <a href="https://www.iatconsulting.com.br/termos-condicoes" target="_blank" rel="noopener noreferrer" className="hover:underline">Termos de uso</a>
          {' | '}
          <a href="https://www.iatconsulting.com.br/politica-privacidade" target="_blank" rel="noopener noreferrer" className="hover:underline">Política de privacidade</a>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
