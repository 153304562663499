// src/components/CadastroIncorporado.js
import React, { useState } from 'react';

const CadastroIncorporado = () => {
  const [cadastro, setCadastro] = useState({
    nome: '',
    telefone: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCadastro((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleCadastroSubmit = (e) => {
    e.preventDefault();

    // Validação básica
    if (!cadastro.nome || !cadastro.telefone) {
      alert('Por favor, preencha todos os campos.');
      return;
    }

    // Envia os dados para a API do WhatsApp Business
    fetch('https://seu-backend.com/cadastrar', { // Substitua pela URL do seu backend
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Adicione headers de autenticação se necessário
      },
      body: JSON.stringify(cadastro),
    })
    .then(response => response.json())
    .then(data => {
      if(data.success){
        alert('Cadastro realizado com sucesso!');
        // Opcional: adicionar uma mensagem no chat ou redirecionar
      } else {
        alert('Erro no cadastro.');
      }
    })
    .catch(error => {
      console.error('Erro:', error);
      alert('Ocorreu um erro ao realizar o cadastro.');
    });
  };

  return (
    <div style={{ padding: '20px', backgroundColor: '#333', width: '100%', maxWidth: '800px', marginBottom: '20px', borderRadius: '8px' }}>
      <h2 style={{ color: '#fff', marginBottom: '10px' }}>Cadastro Incorporado</h2>
      <form onSubmit={handleCadastroSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <input
          type="text"
          id="nome"
          placeholder="Nome"
          value={cadastro.nome}
          onChange={handleChange}
          required
          style={{ padding: '10px', borderRadius: '4px', border: '1px solid #555', backgroundColor: '#444', color: '#fff' }}
        />
        <input
          type="tel"
          id="telefone"
          placeholder="Telefone"
          value={cadastro.telefone}
          onChange={handleChange}
          required
          style={{ padding: '10px', borderRadius: '4px', border: '1px solid #555', backgroundColor: '#444', color: '#fff' }}
        />
        <button type="submit" style={{ padding: '10px', borderRadius: '4px', border: 'none', backgroundColor: '#4CAF50', color: '#fff', cursor: 'pointer' }}>
          Cadastrar
        </button>
      </form>
    </div>
  );
};

export default CadastroIncorporado;
