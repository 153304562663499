// src/components/WhatsAppConfig.js
import React from 'react';
import CadastroIncorporado from './CadastroIncorporado';

const WhatsAppConfig = () => {
  return (
    <div style={{ padding: '20px', backgroundColor: '#242424', minHeight: '100%', width: '100%', maxWidth: '800px' }}>
      <h1 style={{ color: '#fff', textAlign: 'center', marginBottom: '20px' }}>Configuração do WhatsApp</h1>
      <CadastroIncorporado />
      {/* Adicione outras funcionalidades relacionadas ao WhatsApp aqui */}
    </div>
  );
};

export default WhatsAppConfig;
