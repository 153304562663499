// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChatInterface from './components/ChatInterface'; // Componente de chat
import ChatSettings from './components/ChatSettings'; // Componente de configuração
import LoginScreen from './components/LoginScreen'; // Componente de login
import ChatAgente from './components/ChatAgente'; // Componente ChatAgente
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginScreen />} /> {/* Rota de login */}
        <Route path="/chat" element={<ChatInterface />} /> {/* Rota de chat do atendimento */}
        <Route path="/settings" element={<ChatSettings />} /> {/* Rota de configurações */}
        <Route path="/agente" element={<ChatAgente />} /> {/* Rota de ChatAgente */}
      </Routes>
    </Router>
  );
}

export default App;
